import React from "react";
import { Container, Link, Stack, Typography } from "@mui/joy";

import LaunchIcon from '@mui/icons-material/Launch';

export default function BrandFooter() {
    return (
        <footer style={{ backgroundColor: '#1a1a1a', padding: '10px 0' }}>
            <Container sx={{ height: '100%' }}>
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={{ xs: 2, sm: 3 }}
            >
                    <Typography textColor={'neutral.300'} sx={{ fontSize: { xs: 'sm', sm: 'md'} }}>
                        Разработчик: <Link href={'https://t.me/kiryushka01'} fontWeight={'xl'} color="neutral" textColor={'neutral.100'} endDecorator={<LaunchIcon/>}> @kirill_skryabin</Link>
                    </Typography>
                    <Typography textColor={'neutral.300'} sx={{ fontSize: { xs: 'sm', sm: 'md'} }}>
                        Заказать разработку: <Link href={'https://avito.ru/brands/250a7650e41e2fb25b3e738f0397d9f5'} color="neutral" fontWeight={'xl'} textColor={'neutral.100'} endDecorator={<LaunchIcon/>}> перейти</Link>
                    </Typography>
                </Stack>
            </Container>
        </footer>
    )
}