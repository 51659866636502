import { Chip, Container, Grid, Typography } from "@mui/joy";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import BigCardItem from "../../components/CardProductItem/BigCardItem";
import ContactBlock from "../../components/MainPageUI/ContactBlock";

import { motion } from "framer-motion";
import { Helmet } from "react-helmet";


export default function WebContactPage({ }) {

    useEffect(() => {
        window.scrollTo({ 
            top: 0, 
            behavior: 'smooth' 
        });
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0, transition: '2s'  }}
            animate={{ opacity: 1, transition: '2s' }}
            exit={{ opacity: 0, transition: '3s' }}
        >
            <Helmet>
                <title>{`Tsunami | Контакты`}</title>
                <meta name="description" content="Свяжитесь с нами для заказа или получения дополнительной информации о ресторане Tsunami. Мы всегда рады ответить на ваши вопросы и принять ваш заказ." />
                <meta name="keywords" content="кафе, Пермь, ресторан, еда, напитки, десерты, паназиатская кухня, Tsunami, контакты, Tsunami, телефон, адрес, email, заказ еды, паназиатская кухня, ресторан" />
                
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content={`Tsunami | Контакты`} />
                <meta property="og:description" content="Свяжитесь с нами для заказа или получения дополнительной информации о ресторане Tsunami. Мы всегда рады ответить на ваши вопросы и принять ваш заказ." />
                {/* <meta property="og:image" content="https://yourcafe.com/images/cafe-interior.jpg" /> */}
                <meta property="og:url" content="http://tsunami-perm.ru/" />
                <meta property="og:type" content="website" />

                {/* Twitter Card Meta Tags */}
                {/* <meta name="twitter:card" content="summary_large_image" /> */}
                <meta name="twitter:title" content={`Tsunami | Контакты`} />
                <meta name="twitter:description" content="Свяжитесь с нами для заказа или получения дополнительной информации о ресторане Tsunami. Мы всегда рады ответить на ваши вопросы и принять ваш заказ." />
                {/* <meta name="twitter:image" content="https://yourcafe.com/images/cafe-interior.jpg" /> */}
            </Helmet>
            <Container>
                <ContactBlock />
            </Container>
        </motion.div>
    )
}