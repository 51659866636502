import { Card, CardContent, CardCover, Typography } from "@mui/joy";
import React, { useContext, useEffect, useMemo, useState } from "react";
import CircularLoad from "../../UI/CircularLoad";
import Information from "../../../utils/utils_tilipad";
import { LayoutContext } from "../../context";



export default function CardMenuItem({ item }) {
    const [ image, setImage ] = useState('');
    const { page_inform } = useContext(LayoutContext);

    const inform = useMemo(() => {
        if (page_inform) {
            return Information(page_inform);
        }
        else return {};
    }, [page_inform]);

    useEffect(() => {
        const _card = inform?.menu_image?.findIndex((element) => element.uuid_group[0].text === item.mgrp_ID)
        if (_card > -1) setImage(inform?.menu_image[_card]?.image_group?.url);
        else setImage(undefined);
    }, [inform]);

    return (
        <Card 
            size="md" 
            sx={(theme) => ({
                height: '20vw', 
                maxHeight: 200, 
                minHeight: 140,
                borderRadius: 'xl',
                border: 'none',
                lineHeight: '17px',
                p: 2,
                [theme.breakpoints.down('small')]: {
                    p: 1
                }
            })}
        >
            <CardCover sx={{ ...( image === '' && { display: 'flex', justifyContent: 'center', alignItems: 'center' }) }}>
                { image == '' ?
                    <CircularLoad />
                    :
                    <img src={ image == undefined ? '/default.png' : image } loading="lazy" alt=""/>
                }
            </CardCover>
            <CardCover
                sx={{ background: 'rgba(0,0,0,0.2)'}}
            />
            <CardContent 
                sx={{ justifyContent: "flex-end", alignItems: "flex-start" }}
            >
                <Typography 
                    fontWeight={'bold'} 
                    level="title-md"
                    sx={(theme) => ({
                        lineHeight: 1.1,
                        backgroundColor: 'white', 
                        py: 1, px: 2, 
                        borderRadius: 'lg',
                        [theme.breakpoints.down('sm')]: {
                            fontSize: theme.fontSize.sm
                        }
                    })} 
                >      
                    {item.mgrp_Name}
                </Typography>
            </CardContent>
        </Card>
    )
}