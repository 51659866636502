import { CSSTransition, Transition } from 'react-transition-group';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { Avatar, Box, Button, DialogContent, DialogTitle, Grid, Input, Link, ModalClose, ModalOverflow, Stack, Typography, useTheme } from '@mui/joy';

import { useEffect, useState } from 'react';


import DialogAuth from './Dialogs/DialogAuth';
import DialogAccount from './Dialogs/DialogAccount';


export default function ModalAccount ({ modalAccount, setModalAccount, user, setUser }) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const [ restorePwd, setRestorePwd ] = useState(false);
    useEffect(() => {
        if (!modalAccount) setRestorePwd(false);
    }, [modalAccount]);

    useEffect(() => {
        console.log('[modalAccount]', user);
        if (Object.keys(user).length) setModalAccount(true);
    }, []);

    
    function authorizationUser(_phone, _pwd) {
        setOpen(false);
        setTimeout(() => {
            setUser({
                phone: _phone,
                pwd: _pwd
            });
            setOpen(true);
        }, 300);
    }

    function logoutUser() {
        setOpen(false);
        setTimeout(() => {
            setUser({ });
            setOpen(true);
        }, 300);
    }

    return (
        <Transition in={modalAccount} timeout={500}>
            {(state) => (
                <Modal
                    keepMounted
                    open={!['exited', 'exiting'].includes(state)}
                    onClose={() => setModalAccount(false)}
                    slotProps={{
                        backdrop: {
                            sx: {
                                padding: 0,
                                opacity: 0,
                                backdropFilter: 'none',
                                transition: `opacity 200ms, backdrop-filter 500ms`,
                                ...{
                                    entering: { opacity: 1, backdropFilter: 'blur(8px)' },
                                    entered: { opacity: 1, backdropFilter: 'blur(8px)' },
                                }[state],
                            },
                        },
                    }}
                    sx={{
                        visibility: state === 'exited' ? 'hidden' : 'visible',
                    }}
                >   
                    <ModalOverflow sx={{ height: 'auto' }}>
                        <CSSTransition in={open} timeout={300} classNames={'account'}>
                            {Object.keys(user).length ?
                                <DialogAccount state={state} user={user} logoutUser={logoutUser}/>
                            : 
                                <DialogAuth state={state} authorizationUser={authorizationUser}/>
                            }
                        </CSSTransition>
                    </ModalOverflow>
                </Modal>
            )}
        </Transition>
    )
}
