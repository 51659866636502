import { Skeleton, Stack, Typography } from "@mui/joy";
import { useContext, useEffect, useMemo, useState } from "react";
import Information, { isCafeOpen, isDeliveryOpen } from "../../../utils/utils_tilipad";
import { LayoutContext } from "../../context";


const cafeOpenTime = "09:00";  // Время открытия кафе
const cafeCloseTime = "21:00"; // Время закрытия кафе
const deliveryStartTime = "08:30"; // Время начала доставки (30 мин. раньше открытия кафе)

export default function WorkIndicators({ }) {
    const { page_inform } = useContext(LayoutContext);

    const inform = useMemo(() => {
        if (page_inform) {
            return Information(page_inform);
        }
        return undefined;
    }, [page_inform]);

    const checkCafe = useMemo(() => {
        if (inform == undefined) return undefined
        return ( isCafeOpen(inform) ? true : false )
    }, [inform])

    const checkDelivery = useMemo(() => {
        if (inform == undefined) return undefined
        return ( isDeliveryOpen(inform) ? true : false )
    }, [inform])

    return (
        <Stack direction={'column'} justifyContent={'center'} spacing={0}>
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                sx={{ position: 'relative', height: '20px', minWidth: '100%' }}
            >
                <Skeleton sx={{ }} loading={ checkDelivery != undefined ? false : true }>
                    <span className={ checkDelivery ? 'pulse_success' : 'pulse_danger' }></span>
                    <Typography level={'title-sm'}>
                        { checkDelivery ? 'Доставка работает' : 'Доставка закрыта' }
                    </Typography>
                </Skeleton>
            </Stack>
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                sx={{ position: 'relative', height: '20px', minWidth: '100%' }}
            >
                <Skeleton sx={{ }} loading={ checkCafe != undefined ? false : true }>
                    <span className={ checkCafe ? 'pulse_success' : 'pulse_danger' }></span>
                    <Typography level={'title-sm'}>
                        { checkCafe ? 'Принимаем заказы' : 'Мы закрыты' }
                    </Typography>
                </Skeleton>
            </Stack>
        </Stack>
    )
}