import { Link, Stack } from "@mui/joy";
import { useContext, useMemo } from "react";
import Information from "../../utils/utils_tilipad";
import { LayoutContext } from "../context";




export default function SocialNetwork({  }) {
    const { page_inform } = useContext(LayoutContext);

    const inform = useMemo(() => {
        if (page_inform) {
            return Information(page_inform);
        }
        else return {};
    }, [page_inform]);

    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={0}
            sx={{ mb: 3 }}
        >
            {inform?.social_network?.map((item, index) => 
                <Link href={item.link_social_network.url} key={index}><img alt={item.image_social_network.alt} style={{ fontSize: 30, width: 40 }} src={item.image_social_network.url} /></Link>
            )}
        </Stack>
    )
}