import { Box, Container, Divider, Link, Skeleton, Stack, Typography } from "@mui/joy";

import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import { useContext, useMemo } from "react";
import Information from "../../utils/utils_tilipad";
import SocialNetwork from "../UI/SocialNetwork";
import { LayoutContext } from "../context";

export default function ContactBlock({ }) {
    const { page_inform } = useContext(LayoutContext);

    const inform = useMemo(() => {
        if (page_inform) {
            return Information(page_inform);
        }
        else return {};
    }, [page_inform]);

    const zone_delivery = useMemo(() => {
        if (page_inform) {
            return page_inform.data.zone_delivery.url;
        }
        else return undefined;
    }, [page_inform]);

    return (
        <Box
            sx={(theme) => ({
                py: 5, mt: 2, mb: 5, backgroundColor: "#fff",
                borderRadius: 'xl',
                [theme.breakpoints.down('sm')]: {
                    mt: 3,
                    py: 3
                }
            })}
        >
            <Container>
                <Stack
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={{ xs: 3, sm: 3, md: 5 }}
                    direction={{ xs: 'column', md: 'row' }}
                >
                    <Box id={"delivery"} sx={{ mt: 2, position: "relative", overflow: "hidden", minWidth: '50%', width: { xs: '100%', md: '60%' } }}>
                        {/* <Box sx={{ mb: 2 }}>
                            <Typography level="h2">
                                Доставка
                            </Typography>
                            <Typography level="h4" color="danger">
                                Минимальная сумма заказа: 1000 руб.
                            </Typography>
                            <Typography level="title-lg">
                                Стоимость доставки:
                            </Typography>
                            <Typography level="body-lg">
                                Красная зона (центр) - 150 руб.
                            </Typography>
                            <Typography level="body-lg">
                                Красная зона (центр) - 150 руб.
                            </Typography>
                        </Box> */}
                        {/* level="h4" */}
                        <Typography sx={{ width: '100%', mb: 3 }} level="h2">
                            Зона доставки
                        </Typography>
                        <a href="https://yandex.ru/maps/50/perm/?utm_medium=mapframe&utm_source=maps" style={{color: "#eee", fontSize: 12, position: "absolute", top: 0 }}>
                            
                        </a>
                        <iframe src={zone_delivery} height={"100%"} width={"100%"} style={{ borderRadius: '15px', border: "none", position: "relative", minHeight: '400px', minWidth: '50%' }}>
                        </iframe>
                    </Box>

                    <Box id={"contacts"} sx={{ width: { xs: '100% !important', md: 'auto' } }}>
                        <Box sx={{ mb: 2 }}>
                            <Typography level="h2">
                                Самовывоз
                            </Typography>
                            <Typography level="h4" color="danger">
                                От любой суммы
                            </Typography>
                            <Typography level="body-lg">
                                Оплата заказа при получении
                            </Typography>
                        </Box>
                        <Divider/>
                        <Typography sx={{ mt: 2, width: '100%', fontWeight: 'bold', mb: 3 }} level="h4">
                            Контакты
                        </Typography>
                        
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Box sx={{ display: 'flex', position: 'relative', height: '30px', minWidth: '200px' }}>
                                <Skeleton sx={{ width: 'auto', height: 'auto' }} loading={ inform.phone ? false : true }>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <PhoneIcon color="primary" sx={{ fontSize: 30 }}/>
                                        <Link
                                            href={`tel:${inform.phone}`}
                                            fontWeight={'lg'} 
                                            color="neutral"
                                            sx={{ fontFamily: 'Nunito', fontSize: 18, fontWeight: 'bold' }}>
                                                {inform.phone}
                                        </Link>
                                        
                                    </Stack>
                                </Skeleton>
                            </Box>

                            {/* <Box sx={{ display: 'flex', position: 'relative', height: '30px', minWidth: '200px' }}>
                                <Skeleton sx={{ width: 'auto', height: 'auto' }} loading={ inform.email ? false : true }>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <AlternateEmailIcon color="primary" sx={{ fontSize: 30 }}/>
                                        <Link
                                            href={`mailto:${inform.email}`}
                                            fontWeight={'lg'} 
                                            color="neutral"
                                            sx={{ fontFamily: 'Nunito', fontSize: 18, fontWeight: 'bold' }}>
                                                {inform.email}
                                        </Link>
                                        
                                    </Stack>
                                </Skeleton>
                            </Box> */}

                            <Box sx={{ display: 'flex', position: 'relative', height: '30px', minWidth: '200px' }}>
                                <Skeleton sx={{ width: 'auto', height: 'auto' }} loading={ inform.address ? false : true }>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <LocationOnIcon color="primary" sx={{ fontSize: 30 }}/>
                                        <Typography sx={{ fontFamily: 'Nunito', fontSize: 18, fontWeight: 'bold' }}>
                                            {inform.address}
                                        </Typography>
                                        
                                    </Stack>
                                </Skeleton>
                            </Box>

                            <Box sx={{ display: 'flex', position: 'relative', height: '30px', minWidth: '200px' }}>
                                <Skeleton sx={{ width: 'auto', height: 'auto' }} loading={ inform.work_time ? false : true }>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <HistoryToggleOffIcon color="primary" sx={{ fontSize: 30 }}/>
                                        <Typography sx={{ fontFamily: 'Nunito', fontSize: 18, fontWeight: 'bold' }}>
                                            {inform.work_time}
                                        </Typography>
                                        
                                    </Stack>
                                </Skeleton>
                            </Box>

                            <SocialNetwork page_inform={page_inform}/>
                        </Stack>
                    </Box>
                </Stack>
            </Container>
        </Box>
    )
}