import { Box, Link, Button, DialogContent, DialogTitle, Input, ModalClose, ModalDialog, Stack, Typography } from "@mui/joy";
import InputMask from 'react-input-mask';
import { useState } from "react";


import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTheme } from "@emotion/react";


// Окно для авторизациия пользователя
export default function DialogAuth({ state, authorizationUser }) {
    const theme = useTheme();

    const [ viewPwd, setViewPwd ] = useState(false);

    const [ loading, setLoading ] = useState(false);

    const [ phone, setPhone ] = useState('');
    const [ pwd, setPwd ] = useState('');

    function checkUser() {
        setLoading(true);
        setTimeout(() => {
            authorizationUser(phone, pwd);
            setLoading(true);
        }, 300);
    }

    return (
        <ModalDialog
            sx={{
                [theme.breakpoints.up("xl")]: {
                    width: '400px',
                    mx: 0,
                },
                [theme.breakpoints.down("xl")]: {
                    minWidth: '30%',
                    mx: 0,
                },
                [theme.breakpoints.down("lg")]: {
                    width: '40%',
                    mx: 0,
                },
                [theme.breakpoints.down("md")]: {
                    width: '55%',
                    mx: 0,
                },
                [theme.breakpoints.down("sm")]: {
                    width: '90%',
                    p: 3,
                },
                my: 5,
                p: 4,
                borderRadius: 'xl',
                opacity: 0,
                transition: `opacity 200ms`,
                ...{
                    entering: { opacity: 1 },
                    entered: { opacity: 1 },
                }[state],
            }}
        >
            <ModalClose style={{ zIndex: 2 }} />
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <img width={100} style={{ filter: 'grayscale(1)', opacity: '0.3' }} src="../logo.png" />
                <DialogTitle>
                    <Typography level="h2">Авторизация</Typography>
                </DialogTitle>
            </Stack>
            <DialogContent>
                <form style={{ width: '100%' }}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ mt: 2 }}>
                                <Typography sx={{ mb: 0.5 }} level='title-sm' fontWeight={'xl'}>
                                    Номер телефона
                                </Typography>
                                <InputMask mask="+7 (999) 999-99-99" onChange={(e) => setPhone(e.target.value)}>
                                    {(inputProps) => <Input {...inputProps} sx={{ borderRadius: 'md' }} type="tel" placeholder="+7 (000) 000-00-00" variant="outlined" disableUnderline />}
                                </InputMask>
                            </Box>

                            <Box sx={{ mt: 2 }}>
                                <Typography sx={{ mb: 0.5 }} level='title-sm' fontWeight={'xl'}>
                                    Пароль
                                </Typography>
                                <Input endDecorator={viewPwd ? <VisibilityIcon sx={{ cursor: "pointer", }} onClick={() => setViewPwd(!viewPwd)} /> : <VisibilityOffIcon sx={{ cursor: "pointer" }} onClick={() => setViewPwd(!viewPwd)} />} type={viewPwd ? "text" : "password"} sx={{ borderRadius: 'md' }} placeholder={viewPwd ? "Пароль" : "***"} variant="outlined" onChange={(e) => setPwd(e.target.value)} />
                            </Box>

                            <Box>
                                <Link>Забыл пароль</Link>
                            </Box>
                        </Box>
                        <Stack
                            direction='column'
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                            sx={{ width: '100%' }}
                        >
                            <Button
                                sx={{ fontSize: 'lg', width: '100%' }}
                                onClick={() => checkUser()}
                                loading={loading}
                            >
                                Войти
                            </Button>
                            <Typography>
                                Хочешь <Link>зарегистрироваться</Link>?
                            </Typography>
                        </Stack>
                    </Stack>
                </form>
            </DialogContent>
        </ModalDialog>
    )
}