import React, { useContext, useMemo } from "react";
import { Box, Card, CardContent, Container, Grid, Link, List, ListItem, ListItemContent, Skeleton, Stack, Typography } from "@mui/joy";
import {Link as LinkRouter} from "react-router-dom";


import DevicesIcon from '@mui/icons-material/Devices';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import classes from "./Footer.module.css";
import Information from "../../../utils/utils_tilipad";
import WorkIndicators from "../work_indicators/WorkIndicators";
import { LayoutContext } from "../../context";

function Footer({ }) {
    const { page_inform } = useContext(LayoutContext);

    const inform = useMemo(() => {
        if (page_inform) {
            return Information(page_inform);
        }
        else return {};
    }, [page_inform]);

    const navigateList = [
        { url: '/#menu', title: 'Меню' },
        { url: '/#new', title: 'Новинки!' },
        { url: '/#contacts', title: 'Контакты' },
        { url: '/#delivery', title: 'Доставка' },
        { url: '/privacy/', title: 'Политика конфиденциальности' },
    ]

    return (
        <footer className={classes.footer}>
            <Container sx={{ height: '100%' }}>
                <Grid 
                    container 
                    spacing={3} 
                    sx={{flexGrow: 1}}
                    columns={{ sm: 2, xs: 6, md: 12}}>
                    <Grid xs sx={{ display: { xs: 'none', lg: 'block' } }}>
                        <LinkRouter to='/'>
                            <img className={classes.logo} src="../logo.png" />
                        </LinkRouter>
                    </Grid>
                    <Grid xs={5} sm={4} sx={{ py: { xs: 0, sm: 2 }}}>
                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <ul className={classes.list}>
                                {navigateList.map((itm, index) => 
                                    <li>
                                        <LinkRouter key={index} to={itm.url}><Link color="neutral">{itm.title}</Link></LinkRouter>
                                    </li>
                                )}
                            </ul>
                        </Box>
                        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                            <ul className={classes.list}>
                                <li>
                                    <LinkRouter to={/privacy/}><Link color="neutral" sx={{ lineHeight: 1 }}>Политика конфиденциальности</Link></LinkRouter>
                                </li>
                            </ul>
                        </Box>
                        <Typography sx={{ mt: 1 }} fontWeight={'xl'}>
                            Реквизиты
                        </Typography>
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={0.2}
                            sx={{ p: 0 }}
                        >
                            <Typography><Link href="https://www.rusprofile.ru/ip/323595800091861" color="neutral" sx={{ fontSize: 'sm', lineHeight: 1 }}>ИП ПЕПЕЛЯЕВА ОЛЕСЯ ВАЛЕРЬЕВНА</Link></Typography>
                            <Typography sx={{ fontSize: 'sm' }}>ИНН: 591303479642</Typography>
                            {/* <ListItem sx={{ p: 0 }}>
                                <ListItemContent sx={{ fontSize: 'sm' }}>БАНК: ФИЛИАЛ "НИЖЕГОРОДСКОЙ" АО "АЛЬФА-БАНК"</ListItemContent>
                            </ListItem>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemContent sx={{ fontSize: 'sm' }}>БИК: 042202824</ListItemContent>
                            </ListItem>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemContent sx={{ fontSize: 'sm' }}>к/с: 30101810200000000824</ListItemContent>
                            </ListItem> */}
                            <Typography sx={{ fontSize: 'sm', lineHeight: 1 }}>Почта: tsunami-perm@yandex.ru</Typography>
                        </Stack>

                    </Grid>
                    <Grid xs={5} sm={4}>
                        <Typography fontWeight={'xl'}>
                            Статусы
                        </Typography>
                        <Box sx={{ m: 1 }}>
                            <WorkIndicators page_inform={page_inform}/>
                        </Box>
                        <Typography fontWeight={'xl'}>
                            Контакты
                        </Typography>
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={0.5}
                            sx={{ mt: 1 }}
                        >
                            <Box sx={{ display: 'flex', position: 'relative', height: '25px', minWidth: '200px' }}>
                                <Skeleton sx={{ width: 'auto', height: 'auto' }} loading={ inform.phone ? false : true }>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <PhoneIcon color="primary" fontSize="xl"/>
                                        <Typography fontSize={'md'} fontWeight={'xl'}>
                                            {inform.phone}
                                        </Typography>
                                    </Stack>
                                </Skeleton>
                            </Box>

                            <Box sx={{ display: 'flex', position: 'relative', height: '25px', minWidth: '200px' }}>
                                <Skeleton sx={{ width: 'auto', height: 'auto' }} loading={ inform.email ? false : true }>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <AlternateEmailIcon color="primary" fontSize="xl"/>
                                        <Typography fontSize={'md'} fontWeight={'xl'} sx={{ lineHeight: 1 }}>
                                            {inform.email}
                                        </Typography>
                                    </Stack>
                                </Skeleton>
                            </Box>

                            <Box sx={{ display: 'flex', position: 'relative', height: '25px', minWidth: '200px' }}>
                                <Skeleton sx={{ width: 'auto', height: 'auto' }} loading={ inform.address ? false : true }>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <LocationOnIcon color="primary" fontSize="xl"/>
                                        <Typography fontSize={'md'} fontWeight={'xl'} sx={{ lineHeight: 1 }}>
                                            {inform.address}
                                        </Typography>
                                    </Stack>
                                </Skeleton>
                            </Box>

                            <Box sx={{ display: 'flex', position: 'relative', height: '25px', minWidth: '200px' }}>
                                <Skeleton sx={{ width: 'auto', height: 'auto' }} loading={ inform.address ? false : true }>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <HistoryToggleOffIcon color="primary" fontSize="xl"/>
                                        <Typography fontSize={'md'} fontWeight={'xl'} lineHeight={1}>
                                            {inform.work_time}
                                        </Typography>
                                    </Stack>
                                </Skeleton>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid xs>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                        >   
                            <Link underline="none" onClick={() => window.scrollTo({ top: 0 })}>
                                Вверх
                                <KeyboardArrowUpIcon sx={{ fontSize: 40 }}/>
                            </Link>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </footer>
    )
}



export default Footer;