import { createContext, useEffect, useMemo, useState } from "react"
import { ServerCreateInvoice, TilipadCreateGuest, TilipadGetMenu, TilipadGetMenuItems } from "../utils/tilipad";
import { useSinglePrismicDocument } from "@prismicio/react";


export const LayoutContext = createContext();

export const LayoutProvider = ({ children }) => {
    const [ user, setUser ] = useState({});

    const [ basket, setBasket ] = useState([]);
    const [ allCost, setAllCost ] = useState(0);
    const [ countBasketItem, setCountBasketItem ] = useState(0);

    const [ objModal, setObjModal ] = useState({open: false, product: {}, count: undefined, setCount: undefined});

    const [ openSnackSuccess, setOpenSnackSuccess ] = useState(false);

    const [ menu, setMenu ] = useState({});
    // Подгрузка меню
    useEffect(() => {
        TilipadGetMenu().then(data => { 
            if (data != undefined) {
                var m = {}
                m.MenuGroups = data?.map(product => {
                    var _m = product?.unpacked_data;
                    _m.image = product?.photo;
                    return _m;
                })
                console.log(m);
                setMenu(m); 
            }
            else setMenu(data);
        });
    }, []);

    const [ listProductALL, setListProductALL ] = useState([]);
    // Подгрузка блюд
    useEffect(() => {
        TilipadGetMenuItems().then(data => { 
            if (data != undefined) {
                var m = [];
                m = data?.map(product => {
                    var _m = product?.unpacked_data;
                    if (product?.photo)
                        _m.image = product?.photo;
                    return _m;
                })
                setListProductALL(m);
            }
            else setListProductALL(undefined);
        });
    }, []);

    const [ page_inform ] = useSinglePrismicDocument('main_page');

    // Когда меняется корзина
    useMemo(() => {
        // Суммируем количество товаров
        let sum = 0;
        basket.map(itm => sum += itm.count);
        setCountBasketItem(sum);
        // Суммируем стоимость всех товаров
        let sumCost = 0;
        basket.map(itm => sumCost += itm.product.mitm_Price * itm.count);
        setAllCost(sumCost);
    }, [basket]);


    // Добавить элмент в корзину
    const addBasket =  (count, setCount, product) => {
        var basks = basket;
        const bask_id = basket.findIndex((element) => element.product.mitm_ID === product.mitm_ID)
        if (bask_id > -1) {
            basks[bask_id].count = count;
            setBasket([...basks]);
        }
        else {
            const newBask = {product: product, count: 1, setCount: setCount};
            setBasket([...basket, newBask]);
        }

        return count;
    }

    // Уменьшить элемент в корзине
    const removeBasket = (count, product) => {
        var basks = basket;
        
        const bask_id = basket.findIndex((element) => element.product.mitm_ID === product.mitm_ID)
        if (bask_id > -1)
            if (count < 1) basks = basks.filter(p => p.product.mitm_ID !== basks[bask_id].product.mitm_ID);
            else basks[bask_id].count = count;
            setBasket([...basks]);

        return count;
    }

    const isEmpty = (obj) => Object.keys(obj).length === 0;

    // Оформление заказа
    const createOrder = async (data, method_online) => {
        console.log("Create Order");
        // Если оплата онлайн
        if (method_online) {
            const quest = await ServerCreateInvoice(data);
            if (!isEmpty(quest)) {
                setBasket([]);
                return quest;
            }
            else {
                alert('Произошла ошибка формирования заказа. Попробуйте еще раз');
                return {};
            }
        }
        else {
            const quest = await TilipadCreateGuest(data);
            console.log(quest);
            if (!isEmpty(quest)) {
                setOpenSnackSuccess(true);
                setBasket([]);
                return quest
            }
            else {
                alert('Произошла ошибка формирования заказа. Попробуйте еще раз');
                return {};
            }
        }

        
    }
        

    const contextValue = {
        user, setUser,
        basket, setBasket,
        allCost, setAllCost,
        countBasketItem, setCountBasketItem,
        objModal, setObjModal,
        openSnackSuccess, setOpenSnackSuccess,
        menu, setMenu,
        listProductALL, setListProductALL,

        addBasket,
        removeBasket,
        createOrder,

        page_inform
    };
    return (
        <LayoutContext.Provider value={contextValue}>
            { children }
        </LayoutContext.Provider>
    )
}