import { CircularProgress, Stack } from "@mui/joy";


export default function CircularLoad() {
    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ my: 2 }}
        >
            <CircularProgress size="md" />
        </Stack>
    )
}