


export default function Information(page_inform) {
    if (page_inform) {
        var _time_start = '';
        var _time_finish = '';
        var _time_delivery_start = '';
        var _time_delivery_finish = '';

        if (page_inform.data.time_work_start) {
            var _datetime_start = new Date(page_inform.data.time_work_start);
            var hours = _datetime_start.getHours();
            var minutes = _datetime_start.getMinutes();
            _time_start = `${hours.toString().length == 1 ? '0' + hours.toString() : hours.toString()}:${minutes.toString().length == 1 ? '0' + minutes.toString() : minutes.toString()}`
        }
        if (page_inform.data.time_work_finish) {
            var _date_finish = new Date(page_inform.data.time_work_finish);
            var hours = _date_finish.getHours();
            var minutes = _date_finish.getMinutes();
            _time_finish = `${hours.toString().length == 1 ? '0' + hours.toString() : hours.toString()}:${minutes.toString().length == 1 ? '0' + minutes.toString() : minutes.toString()}`
        }

        if (page_inform.data.time_work_delivery_start) {
            var _datetime_start = new Date(page_inform.data.time_work_delivery_start);
            var hours = _datetime_start.getHours();
            var minutes = _datetime_start.getMinutes();
            _time_delivery_start = `${hours.toString().length == 1 ? '0' + hours.toString() : hours.toString()}:${minutes.toString().length == 1 ? '0' + minutes.toString() : minutes.toString()}`
        }
        if (page_inform.data.time_work_delivery_finish) {
            var _date_finish = new Date(page_inform.data.time_work_delivery_finish);
            var hours = _date_finish.getHours();
            var minutes = _date_finish.getMinutes();
            _time_delivery_finish = `${hours.toString().length == 1 ? '0' + hours.toString() : hours.toString()}:${minutes.toString().length == 1 ? '0' + minutes.toString() : minutes.toString()}`
        }
        return {
            phone: page_inform.data.phone,
            email: page_inform.data.email,
            address: page_inform.data.address,
            menu_image: page_inform?.data?.menu_group,
            social_network: page_inform?.data?.social_network,
            work_time: `${page_inform.data.work_days_week ? 'Без вых.' : 'Не работаем в выходные'} с ${_time_start} - ${_time_finish}`,
            data_time: {
                start: _time_start,
                finish: _time_finish
            },
            data_time_delivery: {
                start: _time_delivery_start,
                finish: _time_delivery_finish
            }
        };
    }
    else return {};
};

const checkTime = (current, start, end) => {
    return current >= start && current <= end;
};

export const isCafeOpen = (inform) => {
    const now = new Date();
    const currentTime = now.toTimeString().slice(0, 5); // Текущее время в формате HH:MM
    return checkTime(currentTime, inform.data_time.start, inform.data_time.finish);
};

export const isDeliveryOpen = (inform) => {
    const now = new Date();
    const currentTime = now.toTimeString().slice(0, 5); // Текущее время в формате HH:MM

    return checkTime(currentTime, inform.data_time_delivery.start, inform.data_time_delivery.finish);
};