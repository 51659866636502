import { Card, CardContent, CardCover, Typography, useTheme } from "@mui/joy";
import React from "react";


function BannerItem({title, text, button, image}) {
    const theme = useTheme();

    return (
        <Card sx={{ minHeight: '300px', borderRadius: 'xl' }} variant="plain">
            <CardCover>
                <img src={image} style={{width: '100%'}}/>
            </CardCover>
            <CardCover
                sx={{ background: 'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0.1) 200px)' }}
            />
            <CardContent 
                sx={(theme) => ({
                    justifyContent: 'center', 
                    alignItems: 'flex-start',
                    px: 4, py: 5,
                    [theme.breakpoints.down('sm')]: {
                        px: 2,
                    }
                })}
            >
                <Typography 
                    level="h2" 
                    textColor="#fff" 
                    sx={(theme) => ({
                        fontWeight: 'bold',
                        // [theme.breakpoints.down('sm')]: (theme1) => ({
                        //     fontSize: [theme1.vars.fontSize.xl]
                        // })
                    })}
                >
                    {title}
                </Typography>
                <Typography 
                    level="body-lg"     
                    textColor="#fff"
                    sx={(theme) => ({
                        lineHeight: 1,
                        [theme.breakpoints.down('sm')]: {
                            fontSize: '15px'
                        }
                    })}
                >
                    {text}
                </Typography>
                {button}
            </CardContent>
        </Card>
    )
}

export default BannerItem;