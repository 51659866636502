import Header from './components/UI/header/Header';
import Footer from './components/UI/footer/Footer';
import './App.css';

import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import MainPage from './pages/MainPage';
import MenuPage from './pages/MenuPage';
import { useEffect, useMemo, useState } from 'react';
import Layout from './pages/Layout';
import { CssVarsProvider, extendTheme } from '@mui/joy';
import { useSinglePrismicDocument } from '@prismicio/react';
import WebLayout from './pages/webapp/WebLayout';
import WebMainPage from './pages/webapp/WebMainPage';
import WebMenuPage from './pages/webapp/WebMenuPage';
import WebContactPage from './pages/webapp/WebContactPage';
import { AnimatePresence } from 'framer-motion';

import { LayoutProvider } from './components/context';
import Privacy from './pages/Privacy';
import NotFound from './pages/NotFound';
import { ServerCheckInvoice } from './utils/tilipad';


function App() {

  const theme = extendTheme({
    colorSchemes: {
      light: {
        palette: {
          primary: {
            50: '#e3f4ff',
            100: '#d1edff', // Цвет если soft вариант
            200: '#b8e3ff', // Цвет при наведении на кнопку если soft вариант
            300: '#2a95db', // Цвет бортиков если вариант outlined
            400: '#2178DD', // -
            500: '#009DFF', // Цвет фона для всех вариантов
            600: '#008ce3', // Цвет фона при наведении если solid вариант
            700: '#007ecc', // Цвет фона для всех вариантов (при нажатии)
            800: '#2F4968', // -
            900: '#2F3C4C', // -
          },
          danger: {
            50: "#fde3e4",
            100: "#facccd",
            200: "#f49999",
            300: "#e0666b",
            400: "#cc3340",
            500: "#c30013",
            600: "#b10011",
            700: "#9e000e",
            800: "#8b000b",
            900: "#780008"
          },
          neutral: {
            500: '#242424', // Цвет фона для всех вариантов
            600: '#2e2e2e', // Цвет фона при наведении если solid вариант
            700: '#1f1f1f', // Цвет фона для всех вариантов (при нажатии)
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        
        extrasmall: 300,
        small: 500,
        medium: 800,
        large: 1100,
      },
    },
    fontFamily: {
      body: 'Nunito',
      display: 'Nunito',
      fallback: 'Nunito'
    },
    components: {
      JoyButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            transition: '.2s',
            borderRadius: theme.vars.radius.lg
          })
        }
      },
    },
  });

  return (
    <CssVarsProvider
      theme={theme}
    >
      <BrowserRouter>
        <AnimatePresence>
          <LayoutProvider>
            <Routes>
              <Route path='/' className='hello'
                element={
                  <Layout>
                    <MainPage/>
                  </Layout>
                }
              />
              <Route path="/menu/:slugMenu" 
                element={
                  <Layout>
                    <MenuPage/>
                  </Layout>
                }
              />
              <Route path="/privacy/" 
                element={
                  <Layout>
                    <Privacy/>
                  </Layout>
                }
              />


              <Route path='/webapp/' 
                element={
                  <WebLayout>
                    <WebMainPage />
                  </WebLayout>
                }
              />
              <Route path="/webapp/menu/:slugMenu" 
                element={
                  <WebLayout>
                    <WebMenuPage/>
                  </WebLayout>
                }
              />
              <Route path="/webapp/contacts/" 
                element={
                  <WebLayout>
                    <WebContactPage />
                  </WebLayout>
                }
              />

              {/* Маршрут 404 должен быть последним */}
              <Route path="*" 
                element={
                  <Layout>
                    <NotFound />
                  </Layout>
                } 
              />
            </Routes>
          </LayoutProvider>
        </AnimatePresence>
      </BrowserRouter>
    </CssVarsProvider>
  );
}

export default App;