import React from "react";
import { Container, Typography, } from '@mui/joy';

import { Helmet } from 'react-helmet';

import { motion } from "framer-motion";

export default function NotFound({  }) {

    return (
        <motion.div
            initial={{ opacity: 0, transition: '2s'  }}
            animate={{ opacity: 1, transition: '2s' }}
            exit={{ opacity: 0, transition: '3s' }}
        >
            <Helmet>
                <title>Tsunami | 404 Страницы нет</title>
            </Helmet>
            <Container
                sx={(theme) => ({
                    position: 'relative', py: 5,
                    [theme.breakpoints.down('sm')]: {
                        py: 3
                    }
                })}
            >
                <Typography level="h1">
                    Такой страницы нет
                </Typography>
                <Typography level="h3">К сожалению, страница, которую вы ищете, не существует.</Typography>
            </Container>
        </motion.div>
    )
}