import { Button, Stack, Typography } from "@mui/joy";
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { useContext } from "react";
import { LayoutContext } from "../context";


export default function GhostBlock({ anim, text }) {
    const { setMenu } = useContext(LayoutContext);

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <img className={`${anim ? 'kasper-active' : 'kasper'}`} src="/kasper.png" />
            <Typography sx={{ fontSize: 'lg', fontWeight: 'md' }}>
                {text}
            </Typography>
            <Button sx={{ mt: 2 }} size="lg" startDecorator={<RefreshRoundedIcon />} onClick={() => setMenu({})}>
                Перезагрузить
            </Button>
        </Stack>
    )
}