import { AspectRatio, Box, Button, ButtonGroup, Card, CardContent, CardOverflow, Skeleton, Stack, Tab, tabClasses, TabList, TabPanel, Tabs, Typography, useTheme } from "@mui/joy";
import React, { useContext, useEffect, useState } from "react";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { API_URL, TilipadCheckSyncArticle, TilipadGetImageProduct } from "../../utils/tilipad";
import CircularLoad from "../UI/CircularLoad";
import { LayoutContext } from "../context";
import { loadImageProduct } from "../../utils/utils";


function BigCardItem({ setObjModal, product, basket, setBasket, addBasket, removeBasket, ...props }) {
    const [ productView, setProductView ] = useState(product);
    const [ count, setCount ] = useState(0);
    const [ image, setImage ] = useState('');
    const [ dualElement, setDualElement ] = useState(undefined);

    const { menu, setMenu } = useContext(LayoutContext);

    // При Mount, отслеживаем есть ли товар в корзине
    useEffect(() => {
        const bask_id = basket.findIndex((element) => element.product.mitm_ID === productView.mitm_ID)
        if (bask_id > -1) setCount(basket[bask_id].count);
        else setCount(0);
    }, [basket, productView]);

    // Состояние для отслеживания активной вкладки
    const [activeTab, setActiveTab] = useState(0);
    // Обработчик изменения вкладки
    const handleTabChange = (event, newValue) => {
        if (newValue == 0) setProductView(product);
        else if (newValue == 1) setProductView(dualElement);
        setActiveTab(newValue);
    };

    const { listProductALL } = useContext(LayoutContext);
    useEffect(() => {
        if (product?.mitm_Article) {
            const __elm = TilipadCheckSyncArticle(product, listProductALL);
            setDualElement(__elm);
        }
        else setDualElement(undefined);
    }, [])
    
    // Подгружаем картинку товара
    useEffect(() => {
        if (product?.image !== null && product?.image !== undefined) {
            setImage(API_URL + product?.image);
        } else setImage(undefined);
        // loadImageProduct(product, setImage, menu, setMenu);
    }, [])

    // Уменьшить товар в корзине
    function minusBask (){
        const rerer = removeBasket(count - 1, productView);
        setCount(rerer);
    }
    // Увеличить товар в корзине
    function plusBask (){
        const rerer = addBasket(count + 1, setCount, productView);
        setCount(rerer);
    }

    // Открыть модальное окно
    function openModalFunc() {
        setObjModal({open: true, product: productView, count: count, setCount: setCount});
    }
    
    return (
        <Card variant="plain" size="sm"
            className={'animation-grid'} 
            sx={( theme ) => ({ 
                p: 0, 
                height: '100%',
                minHeight: '200px', 
                borderRadius: 'xl',
                '&:hover': {
                    '& .title': {
                        color: `${theme.colorSchemes.light.palette.danger[500]} !important`
                    }
                }
            })}>
            <CardOverflow>
                <AspectRatio ratio="2" sx={{
                    '& > .MuiAspectRatio-content': {
                        height: {
                            xs: 40,
                            sm: 60
                        },
                        ...( image === '' && { display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, height: '177px' })
                    }
                }}>
                    { image == '' ?
                        <CircularLoad />
                        :
                        <Skeleton loading={false} variant="overlay">
                            <img onClick={() => openModalFunc()} style={{ cursor: 'pointer', borderRadius: 'xl' }} 
                            width={"100%"} 
                            src={ image == undefined ? '/default.png' : image }
                            loading="lazy" alt=""/>
                        </Skeleton>
                    }
                </AspectRatio>
            </CardOverflow>
            <CardContent sx={{ py: 1, pt: 0.2, px: 2, mb: 1, justifyContent: "space-between", alignItems: "flex-start" }}>
                <Box sx={{ width: '100%' }}>
                    <Typography 
                        className={'title'}
                        sx={(theme) => ({ 
                            cursor: 'pointer', 
                            transition: '.4s',
                            lineHeight: 1,
                            [theme.breakpoints.down('small')]: {
                                fontSize: theme.fontSize.md
                            }
                         })}
                        textColor={'neutral.900'}
                        fontWeight={'bold'} 
                        level={"h4"}
                        onClick={() => openModalFunc()}
                        >
                        { productView?.mitm_Name }
                    </Typography>
                    { productView?.Product?.cmpd_Output != undefined ?
                        <Typography fontWeight={'medium'}>
                            Вес: <Typography textColor={'danger.400'}>{ productView?.Product?.cmpd_Output ? `${parseInt(productView?.Product?.cmpd_Output) * productView?.Product?.cmpd_OutputRate} г.` : 'Не указан' }</Typography>
                        </Typography>
                        :
                        <Box></Box>
                    }
                    { productView?.Product?.pitm_Description != undefined ?
                        <Typography noWrap fontWeight={'medium'} sx={{ backgroundColor: 'neutral.100', borderRadius: 'md', p: 1, mt: 2, lineHeight: 1, fontSize: 'sm' }}>
                            { productView?.Product?.pitm_Description }
                        </Typography>
                        :
                        <Box></Box>
                    }

                    { product?.mitm_Article ?
                        <Tabs
                            onChange={handleTabChange}
                            value={activeTab}
                            aria-label="tabs" 
                            defaultValue={0} 
                            sx={{ width: '100%', mt: 2, bgcolor: 'transparent' }}
                        >
                            <TabList
                                disableUnderline
                                sx={{
                                    width: '100%',
                                    p: 0.5,
                                    gap: 0.5,
                                    borderRadius: 'xl',
                                    bgcolor: 'background.level1',
                                    [`& .${tabClasses.root}[aria-selected="true"]`]: {
                                        boxShadow: 'sm',
                                        bgcolor: 'background.surface',
                                    },
                                }}
                            >
                                <Tab disableIndicator value={0} sx={{
                                    width: '100%'
                                }}>
                                    { product?.mitm_Article?.split('|')[1] }
                                </Tab>
                                <Tab disableIndicator value={1} sx={{
                                    width: '100%'
                                }}>
                                    { dualElement?.mitm_Article?.split('|')[1] }
                                </Tab>
                            </TabList>
                        </Tabs>
                        :
                        undefined
                    }
                </Box>
                <Stack 
                    sx={{ mt: { xs: 0.8, md: 1, lg: 1 }, width: '100%' }}
                    direction={{ md: 'column', lg: "row" }}
                    justifyContent="space-between"
                    alignItems={{ md: "flex-start", lg: "center" }}
                    spacing={{ xs: 0.5, lg: 2 }}
                >
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={-0.8}
                    >
                        <Typography sx={(theme) => ({ 
                            [theme.breakpoints.down('small')]: {
                                fontSize: theme.fontSize.sm
                            }
                         })}>
                            Стоимость:
                        </Typography>
                        <Typography sx={ (theme) => ({ 
                            px: 0, 
                            fontWeight: 800,
                            [theme.breakpoints.down('small')]: {
                                fontSize: theme.fontSize.lg
                            }
                         })} level="h4">
                            {productView?.mitm_Price}₽
                        </Typography>
                    </Stack>

                    {
                        count <= 0 ?
                        <Button 
                            sx={{ fontSize: { xs: 'sm', sm: 'md' }, width: { xs: '100%', lg: 'auto' } }}
                            onClick={() => plusBask()}>
                            В корзину
                        </Button>
                        :
                        <ButtonGroup sx={{ height: 'auto', width: { xs: '100%', lg: 'auto' } }} aria-label="outlined button group">
                            <Button 
                                sx={{ px: 1, width: { xs: '100%', lg: 'auto' }, borderRadius: 'var(--joy-radius-lg) 0 0 var(--joy-radius-lg)' }} 
                                variant="outlined"
                                onClick={() => minusBask()}>
                                <RemoveIcon sx={{ fontSize: 20, }} />
                            </Button>
                            <Button disabled sx={{ px: 2, py: 0.45, width: { xs: '100%', lg: 'auto' } }}>
                                <Typography style={{ color: '#0084FF' }} level="body-lg">
                                    {count}
                                </Typography>
                            </Button>
                            <Button 
                                sx={{ px: 1, width: { xs: '100%', lg: 'auto' }, borderRadius: '0 var(--joy-radius-lg) var(--joy-radius-lg) 0' }} 
                                variant="outlined"
                                onClick={() => plusBask()}>
                                <AddIcon sx={{ fontSize: 20 }} />
                            </Button>
                        </ButtonGroup>
                    }
                </Stack>
            </CardContent>
        </Card>
    )
}

export default BigCardItem;