import React, { useContext } from "react";
import { Container, Typography } from '@mui/joy';

import WebMenuCardList from "../../components/MainPageUI/WebMenuCardList";

import { motion } from "framer-motion";
import { LayoutContext } from "../../components/context";
import { Helmet } from "react-helmet";



export default function WebMainPage({ }) {
    const { menu } = useContext(LayoutContext);

    return (
        <motion.div
            initial={{ opacity: 0, transition: '2s'  }}
            animate={{ opacity: 1, transition: '2s' }}
            exit={{ opacity: 0, transition: '3s' }}
        >
            <Helmet>
                <title>Tsunami | Ваше Кафе в Перми</title>
                <meta name="description" content="Ознакомьтесь с нашим разнообразным меню паназиатской кухни. В Tsunami мы предлагаем широкий выбор блюд, приготовленных с любовью и мастерством, включая суши, роллы, поке, wok и многое другое." />
                <meta name="keywords" content="кафе, Пермь, ресторан, еда, напитки, десерты, паназиатская кухня, Tsunami, меню, суши, роллы, лапша, супы, блюда, wok, японская кухня, китайская кухня, доставка еды" />
                
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Tsunami | Ваше Кафе в Перми" />
                <meta property="og:description" content="Ознакомьтесь с нашим разнообразным меню паназиатской кухни. В Tsunami мы предлагаем широкий выбор блюд, приготовленных с любовью и мастерством, включая суши, роллы, поке, wok и многое другое." />
                {/* <meta property="og:image" content="https://yourcafe.com/images/cafe-interior.jpg" /> */}
                <meta property="og:url" content="http://tsunami-perm.ru/" />
                <meta property="og:type" content="website" />

                {/* Twitter Card Meta Tags */}
                {/* <meta name="twitter:card" content="summary_large_image" /> */}
                <meta name="twitter:title" content="Tsunami | Ваше Кафе в Перми" />
                <meta name="twitter:description" content="Ознакомьтесь с нашим разнообразным меню паназиатской кухни. В Tsunami мы предлагаем широкий выбор блюд, приготовленных с любовью и мастерством, включая суши, роллы, поке, wok и многое другое." />
                {/* <meta name="twitter:image" content="https://yourcafe.com/images/cafe-interior.jpg" /> */}
            </Helmet>
            <Container
                sx={(theme) => ({
                    position: 'relative', py: 5,
                    [theme.breakpoints.down('sm')]: {
                        py: 3
                    }
                })}
            >
                <Typography level="h1" sx={(theme) => ({
                    [theme.breakpoints.down('small')]: {
                        fontSize: theme.fontSize.xl3
                    }
                    })}
                >
                    Меню
                </Typography>
                {/* Блок Меню */}
                <WebMenuCardList menuList={menu} />
            </Container>
        </motion.div>
    )
}