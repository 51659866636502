import { Badge, Container, Link, Stack, styled, Typography } from "@mui/joy";
import React, { useContext, useMemo, useState } from "react";
import { Link as LinkRouter } from "react-router-dom";

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import IconButton from '@mui/joy/IconButton';

import { Menu } from "@mui/icons-material";
import ModalBasket from "../../ModalBasket/ModalBasket";

import classes from "./NavigateHeader.module.css";
import WebDrawerLeft from "./drawer/WebDrawerLeft";
import { LayoutContext } from "../../context";
import WorkIndicators from "../work_indicators/WorkIndicators";


export default function WebNavigateHeader({ }) {
    const [ open, setOpen ] = useState(false);

    const { basket, setBasket } = useContext(LayoutContext);
    const { addBasket, removeBasket } = useContext(LayoutContext);
    const { createOrder, countBasketItem, allCost } = useContext(LayoutContext);
    
    const [ modalBasket, setModalBasket ] = useState(false);

    const navigateList = [
        { url: '/#menu', title: 'Меню' },
        { url: '/#new', title: 'Новинки!' },
        { url: '/#contacts', title: 'Контакты' },
        { url: '/#delivery', title: 'Доставка' },
        // { url: '/#cafe', title: 'Кафе' },
    ]

    function checkBasket() {
        if (basket.length >= 1) setModalBasket(true);
    }

    // Хэширвоание Модального окна
    const modal_basket = useMemo(() => {
        return <ModalBasket 
            modalBasket={modalBasket}
            setModalBasket={setModalBasket} />
    }, [modalBasket, basket])

    const ThemeLink = styled(Link)(({ theme }) => ({
        transition: '.3s',
        color: theme.colorSchemes.light.palette.neutral[900],
        '&:hover': {
            fontWeight: theme.fontWeight.xl,
            color: theme.colorSchemes.light.palette.primary[600]
        }
    }));

    return (
        <div className={classes.navigate}>
            {modal_basket}
            <Container sx={{ boxShadow: 3 }}>
                <Stack direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={(theme) => ({
                                py: 1,
                                // mx: 2,
                                // [theme.breakpoints.down("sm")]: {
                                //     mx: 1,
                                // }
                            })
                        }
                    >
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        sx={(theme) => ({
                            py: 1.3,
                            display: 'flex',
                            [theme.breakpoints.down("sm")]: {
                                display: 'none',
                            }
                        })
                    }
                    >  
                        {navigateList.map((itm, index) => 
                            <LinkRouter to={itm.url} key={index}>
                                <ThemeLink underline="none">
                                    {itm.title}
                                </ThemeLink>
                            </LinkRouter>
                        )}
                        
                    </Stack>

                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <IconButton 
                            variant="outlined" 
                            color="neutral" 
                            size="md"
                            onClick={() => setOpen(true)}
                            sx={(theme) => ({
                                my: 0.7,
                                display: 'none',
                                [theme.breakpoints.down("sm")]: {
                                    display: 'flex',
                                }
                            })
                        }
                        >
                            <Menu />
                        </IconButton>

                        <WorkIndicators />
                    </Stack>

                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                    >
                        <Link
                            onClick={() => checkBasket()}
                            underline="none"
                            sx={{ borderRadius: '0px' }}
                            >
                                <Badge color="danger" badgeContent={countBasketItem} size="sm">
                                    <ShoppingCartIcon sx={{ fontSize: 30 }}/>
                                </Badge>
                                <Stack
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={-0.5}
                                    sx={{ pl: countBasketItem? 2 : 1 }}
                                >
                                    <Typography level={'title-sm'} sx={{ fontWeight: 300 }}>Корзина</Typography>
                                    <Typography
                                        level={'title-md'} 
                                        sx={{ m: 0, fontWeight: 'bold' }}
                                    >
                                        {allCost}₽
                                    </Typography>
                                </Stack>
                        </Link>
                    </Stack>
                </Stack>
            </Container>

            <WebDrawerLeft 
                open={open}
                setOpen={setOpen}
                basket={basket}
                setBasket={setBasket}
                addBasket={addBasket}
                removeBasket={removeBasket}
                createOrder={createOrder} />
        </div>
    )
}