import { Link as LinkRouter } from "react-router-dom";
import classes from "./CardMenuItem.module.css"
import CardMenuItem from "./CardMenuItem";

export default function WebBigCardMenu({ item }) {
    return (
        <LinkRouter className={classes.card_link} to={`/webapp/menu/${item.mgrp_ID}`}>
            <CardMenuItem item={item} />
        </LinkRouter>
    )
}