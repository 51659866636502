import { AspectRatio, Box, Button, ButtonGroup, DialogContent, Modal, ModalClose, ModalDialog, ModalOverflow, Skeleton, Stack, Typography, useTheme } from "@mui/joy";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Transition } from "react-transition-group";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CircularLoad from "../UI/CircularLoad";
import { API_URL, TilipadGetImageProduct } from "../../utils/tilipad";
import { LayoutContext } from "../context";
import { loadImageProduct } from "../../utils/utils";


function ModalProduct ({ modal, setModal, addBasket, removeBasket }) {
    const theme = useTheme();
    
    console.log('Modal');
    console.log(modal.product);

    const { menu, setMenu } = useContext(LayoutContext);

    const [ image, setImage ] = useState('');
    useEffect(() => {
        if (modal?.product?.image !== null) {
            setImage(API_URL + modal?.product?.image);
        } else setImage(undefined);
        // Подгружаем картинку товара
        // loadImageProduct(modal.product, setImage, menu, setMenu);
    }, [modal.product])

    const isEmpty = (obj) => Object.keys(obj).length === 0;

    // const weight = useMemo(() => {
    //     if (modal.product.hasOwnProperty('Product')) {
    //         return modal.product.Product.hasOwnProperty('cmpd_Output') ? modal.product.Product.cmpd_Output : '-';
    //     } else return ''
    // }, [modal.product])

    // const description = useMemo(() => {
    //     if (modal.product.hasOwnProperty('Product')) {
    //         return modal.product.Product.hasOwnProperty('pitm_Description') ? modal.product.Product.pitm_Description : '-';
    //     } else return ''
    // }, [modal.product])
 
    function minusBask (){
        removeBasket(modal.count - 1, modal.product);
        modal.setCount(modal.count - 1);
        setModal({ ...modal, count: modal.count - 1 });
    }

    function plusBask (){
        addBasket(modal.count + 1, modal.setCount, modal.product);
        modal.setCount(modal.count + 1);
        setModal({ ...modal, count: modal.count + 1 });
    }

    return (
        <Transition in={modal.open} timeout={200}>
            {(state) => (
                <Modal
                    keepMounted
                    open={!['exited', 'exiting'].includes(state)}
                    onClose={() => setModal({...modal, open: false})}
                    slotProps={{
                        backdrop: {
                            sx: {
                            opacity: 0,
                            backdropFilter: 'none',
                            transition: `opacity 200ms, backdrop-filter 500ms`,
                            ...{
                                entering: { opacity: 1, backdropFilter: 'blur(8px)' },
                                entered: { opacity: 1, backdropFilter: 'blur(8px)' },
                            }[state],
                            },
                        },
                    }}
                    sx={{
                        visibility: state === 'exited' ? 'hidden' : 'visible',
                    }}
                >
                    <ModalOverflow sx={{ height: 'auto' }}>
                        <ModalDialog
                            sx={{
                                width: 600,
                                pt: 6,
                                [theme.breakpoints.down('sm')]: {
                                    px: 1,
                                    pb: 1,
                                },
                                borderRadius: 'xl',
                                opacity: 0,
                                transition: `opacity 200ms`,
                                ...{
                                entering: { opacity: 1 },
                                entered: { opacity: 1 },
                                }[state],
                            }}
                        >
                            <ModalClose style={{ zIndex: 2 }} />
                            <DialogContent>
                                <Stack
                                    direction={'column'}
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={2}
                                    sx={{ height: '100%' }}
                                >
                                    <AspectRatio ratio="4/4" sx={{ width: '100%', borderRadius: 'xl',
                                        '& > .MuiAspectRatio-content': { 
                                            ...( image === '' && { display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, height: '177px' }
                                        )}
                                    }}>
                                        { image == '' ?
                                        <CircularLoad />
                                        :
                                        <Skeleton loading={false} variant="overlay">
                                            <img style={{ borderRadius: 'xl' }} 
                                            width={"100%"} 
                                            src={ image == undefined ? '/default.png' : image }
                                            loading="lazy" alt=""/>
                                        </Skeleton>
                                    }
                                    </AspectRatio>
                                    <Box sx={{ width: '100%' }}>
                                        <Box sx={{ px: 1 }}>
                                            <Typography 
                                                sx={(theme) => ({
                                                    [theme.breakpoints.down('small')]: {
                                                        fontSize: theme.fontSize.xl2
                                                    }
                                                    })} 
                                                fontWeight={'bold'} 
                                                level="h2"
                                                >
                                                    { modal.product.mitm_Name }
                                            </Typography>
                                            { modal?.product?.Product?.cmpd_Output != undefined ?
                                                <Typography fontWeight={'medium'}>
                                                    Вес: <Typography textColor={'danger.400'}>{ modal?.product?.Product?.cmpd_Output ? `${parseInt(modal?.product?.Product?.cmpd_Output) * modal?.product?.Product?.cmpd_OutputRate} г.` : 'Не указан' }</Typography>
                                                </Typography>
                                                :
                                                <Box></Box>
                                            }
                                            { modal?.product?.Product?.pitm_Description != undefined ?
                                                <Box sx={{ mt: 2 }}>
                                                    <Typography fontWeight={'md'}>
                                                        Состав: 
                                                    </Typography>
                                                    <Typography fontWeight={'medium'} sx={{ backgroundColor: 'neutral.100', borderRadius: 'md', p: 1, mt: 2, lineHeight: 1, fontSize: 'sm' }}>
                                                        { modal?.product?.Product?.pitm_Description }
                                                    </Typography>
                                                </Box>
                                                :
                                                <Box></Box>
                                            }
                                            <Box sx={{ mt: 2 }}>
                                                <Stack 
                                                    sx={{ width: '100%' }}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                >
                                                    <Stack
                                                        direction="column"
                                                        justifyContent="center"
                                                        alignItems="flex-start"
                                                        spacing={-0.8}
                                                    >
                                                        <Typography>
                                                            Стоимость:
                                                        </Typography>
                                                        <Typography sx={(theme) => ({
                                                            px: 0, fontWeight: 'xl',
                                                            [theme.breakpoints.down('small')]: {
                                                                fontSize: theme.fontSize.xl2
                                                            }
                                                            })} level="h2">
                                                            { modal.product.mitm_Price }₽
                                                        </Typography>
                                                    </Stack>

                                                    {
                                                        modal.count <= 0 ?
                                                        <Button
                                                            sx={{ fontSize: 'md' }}
                                                            onClick={() => plusBask()}>
                                                            В корзину
                                                        </Button>
                                                        :
                                                        <ButtonGroup sx={{ height: '100%' }} aria-label="outlined button group">
                                                            <Button 
                                                                sx={{ px: 1, borderRadius: 'var(--joy-radius-lg) 0 0 var(--joy-radius-lg)' }} 
                                                                variant="outlined"
                                                                onClick={() => minusBask()}>
                                                                <RemoveIcon sx={{ fontSize: 20, }} />
                                                            </Button>
                                                            <Button disabled sx={{ px: 2, py: 0.59 }}>
                                                                <Typography style={{ color: '#0084FF' }} level="body-lg">
                                                                    { modal.count }
                                                                </Typography>
                                                            </Button>
                                                            <Button 
                                                                sx={{ px: 1, borderRadius: '0 var(--joy-radius-lg) var(--joy-radius-lg) 0' }} 
                                                                variant="outlined"
                                                                onClick={() => plusBask()}>
                                                                <AddIcon sx={{ fontSize: 20 }} />
                                                            </Button>
                                                        </ButtonGroup>
                                                    }
                                                </Stack>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Stack>
                            </DialogContent>
                        </ModalDialog>
                    </ModalOverflow>
                </Modal>
            )}
        </Transition>
    )
}

export default ModalProduct;