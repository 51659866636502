import { Box, Container, Divider, Drawer, Link, ModalClose, Skeleton, Stack, styled, Typography } from "@mui/joy";
import React, { useContext, useMemo, useState } from "react";
import { Link as LinkRouter } from "react-router-dom";

import ModalBasket from "../../../ModalBasket/ModalBasket";

import classes from "../NavigateHeader.module.css";
import { LayoutContext } from "../../../context";
import Information from "../../../../utils/utils_tilipad";


export default function DrawerLeft({ open, setOpen }) {
    const navigateList = [
        { url: '/#menu', title: 'Меню' },
        { url: '/#new', title: 'Новинки!' },
        { url: '/#contacts', title: 'Контакты' },
        { url: '/#delivery', title: 'Доставка' },
        { url: '/#cafe', title: 'Кафе' },
    ]

    const ThemeLink = styled(Link)(({ theme }) => ({
        transition: '.3s',
        color: theme.colorSchemes.light.palette.neutral[900],
        '&:hover': {
            fontWeight: theme.fontWeight.xl,
            color: theme.colorSchemes.light.palette.primary[600]
        }
    }));

    const { page_inform } = useContext(LayoutContext);
    const inform = useMemo(() => {
        if (page_inform) {
            return Information(page_inform);
        }
        else return {};
    }, [page_inform]);

    return (
        <div className={classes.navigate}>
            <Drawer 
                open={open} 
                variant="plain"
                onClose={() => setOpen(false)}
                sx={(theme) => ({
                        display: 'none',
                        [theme.breakpoints.down("sm")]: {
                            display: 'block',
                        }
                    })
                }
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                        ml: 'auto',
                        mt: 2,
                        mr: 2
                    }}
                >
                    <Typography
                        component="label"
                        htmlFor="close-icon"
                        fontSize="sm"
                        fontWeight="lg"
                        sx={{ cursor: 'pointer' }}
                    >
                        Закрыть
                    </Typography>
                    <ModalClose id="close-icon" sx={{ position: 'initial' }} />
                </Box>
                
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                    sx={{
                        mt: 2,
                        flex: 'none',
                        fontSize: 'xl',
                        '& > div': { justifyContent: 'center' },
                    }}
                >   
                    <Container>
                        <Stack direction={'column'} justifyContent={'center'} alignItems={'flex-start'}>
                            <Typography level="h3" fontWeight={'lg'} sx={{ fontWeight: 'bold' }}>
                                <Link href={`tel:${inform.phone}`} underline="none" style={{'color': '#1a1a1a'}} >
                                {inform.phone}
                                </Link>
                            </Typography>
                            <Typography level="title-sm">можно заказать в <Link href="https://t.me/tsunami_perm_bot" sx={{ color: '#C30013', textDecorationColor: '#C30013' }} >@tsunami_perm_bot</Link></Typography>
                        </Stack>
                        <Divider sx={{ my: 2 }}/>
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                        >

                            {navigateList.map((itm, index) => 
                                <LinkRouter key={index}
                                    onClick={() => setOpen(false)} to={itm.url}>
                                    <ThemeLink level="h3" underline={"none"}>
                                        {itm.title}
                                    </ThemeLink>
                                </LinkRouter>
                            )}
                        </Stack>
                    </Container>
                </Stack>
            </Drawer>
        </div>
    )
}