import { Box, CircularProgress, Grid, Skeleton, Stack } from "@mui/joy";
import WebLinkCardMenuItem from "./CardMenuItem/WebLinkCardMenuItem";
import { useMemo, useState } from "react";
import checkEmptyObject from "../../utils/utils";
import GhostBlock from "../UI/GhostBlock";



export default function WebMenuCardList({ menuList }) {
    const [ anim, setAnim ] = useState(false);

    const menu = useMemo(() => {
        if (menuList == undefined) setAnim(true);
        return menuList;
    }, [menuList])
    
    return (
        <Box id={"menu"} sx={(theme) => ({
            mt: 2, flexGrow: 1
        })}
        >
            { menu == undefined ?
                <GhostBlock anim={anim} text={'К сожалению не удалось загрузить меню'} />
                :
            menu?.MenuGroups?.length >= 1 ?
                    <Grid container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, small: 6, sm: 6, md: 6, large: 8 }}
                    >
                        {menu?.MenuGroups?.map((item, index) =>
                            <Grid className='animation-grid' xs={2} small={3} sm={2} md={2} large={2} key={index}>
                                <WebLinkCardMenuItem item={item} />
                            </Grid>
                        )}
                    </Grid>
                    :
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress size="md" />
                    </Stack>
            }
        </Box>
    )
}